import Footer from "~/components/Footer";
import {FooterNavigation} from "~/components/FooterNavigation";
import { Header } from "~/components/Header";
import {Sidebar} from "~/components/Sidebar"
import { Scripts } from "remix";
import { useResponsive } from '../hooks/useResponsive';

export const MainLayout: React.VFC<Props> = ({children}) => {
    const {isMobile,isDesktop} = useResponsive();
    return (
        <div>
        <Header />
        <div className="content">
            <div className="contentMain">
                {children}
            </div>
            {isDesktop && <Sidebar />}
        </div>
        <Footer />
        {isMobile && <FooterNavigation />}
        <Scripts />
        </div>
    );
}