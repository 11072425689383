import {ContentGalleryItem} from './ContentGalleryItem';

export const ContentGalleryList: React.FC<{ value: ContentProps }> = ({ value }) => {
    const { images } = value;
    return (
        <ul className="contentLists">
            {images.map((image) => (
                <ContentGalleryItem key={image.url} images={image} />
            ))}
        </ul>
    );
}