import { ContentItem } from "./ContentItem"
import { ContentGalleryList } from "./ContentGalleryList"

export const CombinedContentComponent: React.FC<{ value: ContentProps }> = ({value}) => {
    const {title_main} = value;
    return(
        <>
        <section>
            <h2>{title_main}</h2>
            <ContentGalleryList value={value}/>
            <ContentItem value={value}/>
        </section>
        </>
    )
}