import { Link } from "remix";

export const ContentGalleryItem: React.FC<{ images: ContentProps['images'][number] }> = ({ images }) => {
    const { img, img_title, url } = images;
    return (
        <li className={url.includes("purpose") ? 'purposeItem' : ""}>
            <Link to={url}>
                <p className="contentList">
                    <img src={`/images/article/top/${img}`} alt="詳細" width="150" height="150" />
                </p>
            </Link>
            <p>{img_title}</p>
        </li>
    )
}