export const ContentItem: React.FC<ContentItemProps> = ({ value }) => {
    const { title, desc, url, img } = value
    return (
        <>
            <div className="contentArticle">
                <div>
                    <h2>{title}から探す</h2>
                    <p>{desc}</p>
                    <button type="button" className="button">
                        <a href={url} className=""> 詳細はこちら </a>
                    </button>
                </div>
                <img src={`/images/article/top/${img}`} alt="詳細" width="150" height="150" />
                <button type="submit" className="button">
                    <a href={url} className=""> 覗いてみる </a>
                </button>
            </div>
        </>
    )
}
