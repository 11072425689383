import { topContent } from "../constants/index"
import { CombinedContentComponent } from "./modules/CombinedContentComponent"


export const ContentList = () => {
    return(
        <>
        <CombinedContentComponent value={topContent[0]}/>
        <CombinedContentComponent value={topContent[1]}/>
        <CombinedContentComponent value={topContent[2]}/>
        <CombinedContentComponent value={topContent[3]}/>
        </>
    )
}
